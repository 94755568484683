'use strict';

function removeWhiteSpaces(inputTarget) {
    inputTarget.value = inputTarget.value.replace(/\s+/g, '');
}

function eventListenerValidateWhiteSpaces() {
    var inputs = $('.validateWhiteSpaces');
    for (var i = 0; i < inputs.length; i++) {
        var input = inputs[i];
        input.addEventListener('change', (e) => {
            removeWhiteSpaces(e.target);
        });
    }
}

module.exports = {
    eventListenerValidateWhiteSpaces: eventListenerValidateWhiteSpaces
};
